import React from "react";
import {Redirect, Route} from "react-router-dom";
import {
    HydraAdmin,
    hydraDataProvider as baseHydraDataProvider,
    fetchHydra as baseFetchHydra
} from "@api-platform/admin";
import parseHydraDocumentation from "@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation";
import authProvider from "./authProvider";
import Resources from "./Resources";
import ResourceGuesser from "@api-platform/admin/lib/ResourceGuesser";
import UserIcon from '@material-ui/icons/People';
import StoreIcon from '@material-ui/icons/Store';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import CategoryIcon from '@material-ui/icons/Category';
import PhotoIcon from '@material-ui/icons/Photo';
import NotificationsIcon from '@material-ui/icons/Notifications';

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = {Authorization: `Bearer ${window.localStorage.getItem("token")}`};

const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});

const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({ api }),
        (result) => {
            switch (result.status) {
                case 401:
                case 403:
                    authProvider.logout();
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [
                            <Route path="/" render={() => {
                                return window.localStorage.getItem("token") ? window.location.reload() : <Redirect to="/login" />
                            }} />
                        ],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseHydraDataProvider(entrypoint, fetchHydra, apiDocumentationParser);

export default () => (
    <HydraAdmin dataProvider={dataProvider} authProvider={authProvider} entrypoint={entrypoint}>
        <ResourceGuesser
            name="affiliates"
            icon={StoreIcon}
            list={Resources.Affiliates.AffiliatesList}
            show={Resources.Affiliates.AffiliatesShow}
            create={Resources.Affiliates.AffiliateCreate}
        />
        <ResourceGuesser
            name="products"
            icon={PhotoIcon}
            list={Resources.Products.ProductsList}
            show={Resources.Products.ProductShow}
            edit={Resources.Products.ProductEdit}
        />
        <ResourceGuesser
            name="users"
            icon={UserIcon}
            list={Resources.Users.UsersList}
            show={Resources.Users.UsersShow}
            edit={Resources.Users.UsersEdit}
        />
        <ResourceGuesser
            name="shops"
            icon={StoreIcon}
            list={Resources.Shops.ShopsList}
            show={Resources.Shops.ShopsShow}
            edit={Resources.Shops.ShopsEdit}
        />
        <ResourceGuesser
            name="discounts"
            icon={LoyaltyIcon}
            list={Resources.Discounts.DiscountsList}
        />
        <ResourceGuesser
            name="customer_issues"
            list={Resources.CustomerIssues.CustomerIssuesList}
        />
        <ResourceGuesser
            name="notifications"
            icon={NotificationsIcon}
            create={Resources.Notifications.NotificationCreate}
            list={Resources.Notifications.NotificationList}
        />
        <ResourceGuesser
            name="categories"
            icon={CategoryIcon}
            list={Resources.Categories.CategoriesList}
            create={Resources.Categories.CategoryCreate}
            edit={Resources.Categories.CategoryEdit}
        />
    </HydraAdmin>
);
