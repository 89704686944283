import React from "react";
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";
import {FunctionField} from "react-admin";


const ShopsShow = props => (
    <ShowGuesser {...props}>
        <FieldGuesser source="name" addLabel={true} />
        <FieldGuesser source="phoneNumber" addLabel={true} />
        <FieldGuesser source="validated" addLabel={true} />
        <FieldGuesser source="validatedAt" addLabel={true} />
        <FieldGuesser source="createdAt" addLabel={true} />
        <FunctionField
            source="instagramBusinessAccountName"
            label="Page Instagram"
            render={record => {
                return record.instagramBusinessAccountName ? (
                    <a href={`https://instagram.com/${record.instagramBusinessAccountName}`} target="_blank" rel="noopener noreferrer">
                        {record.instagramBusinessAccountName}
                    </a>
                ) : ''
            }}
        />
    </ShowGuesser>
);

export default ShopsShow
