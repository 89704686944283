import React from "react";
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";
import { TextField, SingleFieldList, ChipField, ArrayField, NumberField, TabbedShowLayout, Tab, } from 'react-admin';
import Avatar from "@material-ui/core/Avatar";

const AffiliateAvatar = ({record}) => {
    if (record === null) {
        return <Avatar variant="rounded"/>;
    }

    if (record.image !== undefined && record.image !== null) {
        return <Avatar src={record.image} variant="rounded"/>
    }

    if (record.name !== null) {
        return <Avatar alt={record.name} variant="rounded">{record.name}</Avatar>
    }

    return <Avatar variant="rounded"/>;
};

const AffiliatesShow = props => (
    <TabbedShowLayout>
        <Tab label={"Summary"}>
            <ShowGuesser {...props}>
                <AffiliateAvatar label="#"/>
                <FieldGuesser source="name" label="Nom"/>
                <TextField source="currencyCode" label="Devise"/>
                <NumberField source="percentCommission" label="Commission"/>
                <ArrayField source="commissionRange" {...props}>
                    <SingleFieldList>
                        <ChipField source="max" key="max"/>
                    </SingleFieldList>
                </ArrayField>
                <TextField source="websiteLink" label="URL"/>
                <TextField source="primaryRegion.name" label="Pays"/>
            </ShowGuesser>
        </Tab>
    </TabbedShowLayout>
);

export default AffiliatesShow
