import React from "react";
import {
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";

import {BooleanInput, Filter, ReferenceInput, AutocompleteInput, TextField, ReferenceField} from 'react-admin';

const DiscountFilters = (props) => (
    <Filter {...props}>
        <BooleanInput label="Used" source="exists[usedAt]"/>
        <ReferenceInput label="Shop" source="shop" reference="shops" filterToQuery={searchText => ({ name: searchText })}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Owner" source="owner" reference="users" filterToQuery={searchText => ({ name: searchText })}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

const DiscountsList = props => (
    <ListGuesser {...props} filters={<DiscountFilters/>}>
        <ReferenceField label="Owner" source="owner" reference="users">
            <TextField source="name" />
        </ReferenceField>
        <ReferenceField label="Shop" source="shop" reference="shops">
            <TextField source="name" />
        </ReferenceField>
        <FieldGuesser source="amount_in_percent" />
        <FieldGuesser source="usedAt" />
        <FieldGuesser source="createdAt" />
    </ListGuesser>
);

export default DiscountsList;