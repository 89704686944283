import React from "react";
import { EditGuesser, InputGuesser } from '@api-platform/admin';
import { ReferenceInput, AutocompleteInput, TextInput } from 'react-admin';

const CategoryEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source={"name"} />
        <InputGuesser source={"iconName"} />
        <InputGuesser source={"iconType"} />
        <TextInput source={"iconColor"} />
        <ReferenceInput label="Parent Category" source="parent" reference="categories" allowEmpty={true}>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </EditGuesser>
);

export default CategoryEdit;
