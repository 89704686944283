import React from "react";
import {
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";

import {TextField, ReferenceField} from 'react-admin';


const CustomerIssuesList = props => (
    <ListGuesser {...props}>
        <ReferenceField label="Owner" source="owner" reference="users">
            <TextField source="name" />
        </ReferenceField>
        <FieldGuesser source="message" />
        <FieldGuesser source="createdAt" />
    </ListGuesser>
);

export default CustomerIssuesList;