import React from "react";
import {
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";

const NotificationList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source="title" />
    </ListGuesser>
);

export default NotificationList;