import React from "react";
import {
    EditGuesser,
    InputGuesser,
} from "@api-platform/admin";

import { ReferenceInput, SelectInput } from 'react-admin'

const ProductEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser multiline source="description" />
        <ReferenceInput label="Category" source="category" reference="categories">
            <SelectInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="trackingLink" />
    </EditGuesser>
);

export default ProductEdit