import React from "react";
import { CreateGuesser, InputGuesser } from '@api-platform/admin';
import { ReferenceInput, SelectInput, TextInput } from 'react-admin';

const CategoryCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"name"} />
        <InputGuesser source={"iconName"} />
        <InputGuesser source={"iconType"} />
        <TextInput source={"iconColor"} />
        <ReferenceInput label="Category" source="parent" reference="categories" allowEmpty={true}>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);

export default CategoryCreate;
