import React from "react";
import {
    InputGuesser,
} from "@api-platform/admin";
import CreateGuesser from "@api-platform/admin/lib/CreateGuesser";

const NotificationCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source="title"/>
        <InputGuesser source="body"/>
        <InputGuesser source="data"/>
        <p>Attention, ces champs configurent les notifications en elles-mêmes</p>
        <InputGuesser source="ttl"/>
        <InputGuesser source="priority"/>
        <InputGuesser source="sound"/>
        <InputGuesser source="badge"/>
        <InputGuesser source="category"/>
        <InputGuesser source="channelId"/>
    </CreateGuesser>
);

export default NotificationCreate;
