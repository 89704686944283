import React from "react";
import { EditGuesser, InputGuesser } from "@api-platform/admin";
import { ReferenceInput, SelectInput } from 'react-admin';

const AffiliatesEdit = props => {
    const transform = function (args) {
        return {
            "@id": args['@id'],
            id: args.id,
            name: args.name,
            category: args.category,
            phoneNumber: args.phoneNumber,
            validated: args.validated,
            payments: args.payments,
        };
    }

    return (
        <EditGuesser {...props} transform={transform}>
            <InputGuesser source="name"/>
            <InputGuesser source={"awinId"} />
            <ReferenceInput label="Category" source="category" reference="categories" allowEmpty={true}>
                <SelectInput optionText="name" />
            </ReferenceInput>
        </EditGuesser>
    );
}

export default AffiliatesEdit
