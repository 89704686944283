import React from "react";
import {
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";

import {TextField, ReferenceField} from 'react-admin';


const CategoriesList = props => (
    <ListGuesser {...props}>
        <FieldGuesser source="name" />
        <ReferenceField label="Parent" source="parent" reference="categories">
            <TextField source="name" />
        </ReferenceField>
    </ListGuesser>
);

export default CategoriesList;