import React from "react";
import {
    ListGuesser,
    FieldGuesser,
} from "@api-platform/admin";
import {TextInput, Filter, ReferenceField, TextField, EmailField} from 'react-admin';

const UserFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Name" source="name"/>
        <TextInput label="Email" source="email"/>
    </Filter>
);

const UsersList = props => (
    <ListGuesser {...props} filters={<UserFilter/>}>
        <EmailField source="email"/>
        <FieldGuesser source="name"/>
        <ReferenceField label="Shop" source="shop" reference="shops">
            <TextField source="name" />
        </ReferenceField>
        <FieldGuesser source="createdAt"/>
    </ListGuesser>
);

export default UsersList;
