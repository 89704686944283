import React from "react";
import {
    ShowGuesser,
    FieldGuesser
} from "@api-platform/admin";
import {FunctionField, ReferenceField, TextField, ImageField, UrlField} from "react-admin";


const ProductShow = props => (
    <ShowGuesser {...props}>
        <ImageField source="picture.uri" label="Picture"/>
        <FieldGuesser source="name" addLabel={true}/>
        <FieldGuesser source="description" addLabel={true}/>
        <FieldGuesser source="validated" addLabel={true}/>
        <FieldGuesser source="validatedAt" addLabel={true}/>
        <FieldGuesser source="publish" addLabel={true}/>
        <FieldGuesser source="new" addLabel={true}/>
        <FieldGuesser source="discountedByShop" addLabel={true}/>
        <FieldGuesser source="newDiscountedPrice" addLabel={true}/>
        <FieldGuesser source="createdAt" addLabel={true}/>
        <TextField source="favoritesCount" addLabel={true}/>
        <FunctionField
            source="priceInCents"
            label="Price"
            render={record => {
                return record.priceInCents != null ? record.priceInCents / 100 + " €" : null;
            }}
        />
        <ReferenceField label="Shop" source="shop" reference="shops">
            <TextField source="name"/>
        </ReferenceField>
        <ReferenceField label="Owner" source="owner" reference="users">
            <TextField source="name"/>
        </ReferenceField>
        <UrlField source="trackingLink" addLabel={true} target="_blank"/>
    </ShowGuesser>
);

export default ProductShow