import React from "react";
import {
    ListGuesser,
    FieldGuesser
} from "@api-platform/admin";
import {Filter, TextField, TextInput, BooleanInput} from "react-admin";
import Avatar from "@material-ui/core/Avatar";

const AffiliateFilter = (props) => (
    <Filter {...props}>
        <TextInput label="Nom" source="name"/>
        <BooleanInput label="Devise" source="currencyCode"/>
        <BooleanInput label="Pays" source="primaryRegion.name"/>
    </Filter>
);

const AffiliateAvatar = ({record}) => {
    if (record === null) {
        return <Avatar variant="rounded"/>;
    }

    if (record.image !== undefined && record.image !== null) {
        return <Avatar src={record.image} variant="rounded"/>
    }

    if (record.name !== null) {
        return <Avatar alt={record.name} variant="rounded">{record.name}</Avatar>
    }

    return <Avatar variant="rounded"/>;
};

const AffiliatesList = props => (
    <ListGuesser {...props} filters={<AffiliateFilter/>}>
        <AffiliateAvatar label="#"/>
        <FieldGuesser source="name" label="Nom"/>
        <TextField source="currencyCode" label="Devise"/>
        <TextField source="websiteLink" label="URL" variant="link"/>
        <TextField source="primaryRegion.name" label="Pays"/>
    </ListGuesser>
);

export default AffiliatesList;
