import React from "react";
import { CreateGuesser, InputGuesser } from '@api-platform/admin';
import { ReferenceInput, SelectInput } from 'react-admin';

const CategoryCreate = props => (
    <CreateGuesser {...props}>
        <InputGuesser source={"awinId"} />
        <ReferenceInput label="Category" source="category" reference="categories" allowEmpty={true}>
            <SelectInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);

export default CategoryCreate;
