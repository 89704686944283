import React from 'react';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Avatar from '@material-ui/core/Avatar';
import {
    List,
    TextField,
    DateField,
    ReferenceField,
    ShowButton,
    FunctionField,
    Filter,
    BooleanInput,
    ReferenceInput,
    AutocompleteInput
} from "react-admin";
import CardMedia from "@material-ui/core/CardMedia";

const cardStyle = {
    width: 300,
    minHeight: 300,
    margin: '0.5em',
    display: 'inline-block',
    verticalAlign: 'top'
};

const mediaStyles = {
    height: 0,
    paddingTop: '56.25%', // 16:9
};

const ProductFilters = (props) => (
    <Filter {...props}>
        <BooleanInput label="Validated" source="validated"/>
        <BooleanInput label="New" source="new"/>
        <BooleanInput label="Published" source="publish"/>
        <BooleanInput label="Feed Sorting" source="feed"/>
        <ReferenceInput label="Shop" source="shop" reference="shops" filterToQuery={searchText => ({name: searchText})}>
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
        <ReferenceInput label="Owner" source="owner" reference="users" filterToQuery={searchText => ({name: searchText})}>
            <AutocompleteInput optionText="name"/>
        </ReferenceInput>
    </Filter>
);

const ProductAvatar = ({record}) => {
    if (record === null) {
        return <Avatar variant="rounded"/>;
    }

    if (record.picture !== undefined && record.picture !== null) {
        return <Avatar src={record.picture.uri} variant="rounded"/>
    }

    if (record.name !== null) {
        return <Avatar alt={record.name} variant="rounded" >{record.name[0]}</Avatar>
    }

    return <Avatar/>;
};

const ProductGrid = ({ids, data, basePath}) => (
    <div style={{margin: '1em'}}>
        {ids.map(id =>
            <Card key={id} style={cardStyle}>
                <CardHeader
                    title={<TextField record={data[id]} source="name"/>}
                    subheader={<DateField record={data[id]} source="createdAt" showTime={true}/>}
                    avatar={
                        <ReferenceField resource="users" record={data[id]} allowEmpty={true} reference="users"
                                        source="owner" basePath={'/users'} link={'show'}>
                            <ProductAvatar/>
                        </ReferenceField>
                    }
                />
                <CardMedia
                    style={mediaStyles}
                    image={data[id].picture ? data[id].picture.uri : null}
                    title={data[id].name}
                />
                <CardContent>
                    <TextField record={data[id]} source="description"/>
                </CardContent>
                <CardContent>
                    Validated At: <DateField record={data[id]} source="validatedAt" showTime={true}/>
                    <br/>
                    Shop: &nbsp;
                    <ReferenceField label="Shop" resource="shops" record={data[id]} source="shop"
                                    reference="shops" basePath={'/shops'} link={'show'}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <br/>
                    Categorie: &nbsp;
                    <ReferenceField label="Catégorie" resource="categories" record={data[id]}
                                    source="category" reference="categories" basePath={'/categories'} link={"show"}>
                        <TextField source="name"/>
                    </ReferenceField>
                    <br/>
                    Prix: &nbsp;
                    <FunctionField
                        record={data[id]}
                        render={record => {
                            return record.priceInCents != null ? record.priceInCents / 100 + " €" : null;
                        }}
                    />
                </CardContent>
                <CardActions style={{textAlign: 'right'}}>
                    <ShowButton resource="posts" basePath={basePath} record={data[id]}/>
                </CardActions>
            </Card>
        )}
    </div>
);
ProductGrid.defaultProps = {
    data: {},
    ids: [],
};

const ProductList = (props) => (
    <List title="All products" {...props} filters={<ProductFilters/>} sort={false}>
        <ProductGrid/>
    </List>
);

export default ProductList;